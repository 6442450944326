import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F2F1F0',
      neutralLight2: '#D9D7D5',
      neutralDark4: '#585858',
      neutralDark3: '#36464B',
      neutralDark2: '#3C322B',
      neutralDark1: '#000000',
      primaryLight3: '#BEA28F',
      primaryLight2: '#B59A88',
      primaryLight1: '#AC917F',
      primaryDark: '#8B6D59',
      secondaryDark3: '#356577',
      secondaryDark2: '#2B5260',
      secondaryDark1: '#234855',
      dangerLight: '#EE2E31',
    },
  },
  fontFamily: {
    heading: "'Tenor Sans', serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
